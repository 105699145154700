import Vue from "vue";
import App from "./App";
import router from "./router";
import editor from "@tinymce/tinymce-vue";
import CoreuiVue from "@coreui/vue";
import "./main.css";
import { iconsSet as icons } from "./assets/icons/icons.js";
import { callApi } from "./api/apiUtility";
import { i18n } from "./plugins/i18n";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import BootstrapVue from "bootstrap-vue";
import VueMoment from "vue-moment";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import store from "./store";
const moment = require("moment-timezone");
require("moment/locale/th");
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "tinymce/tinymce";

// Theme
import "tinymce/themes/silver/theme";

// Skins
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";

// Plugins
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/paste";
import "tinymce/plugins/autoresize";

import "babel-polyfill";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings as LuxonSettings } from "luxon";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

//Icon
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faPowerOff,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faSearch,
  faArrowRight,
  faStar,
  faUser,
  faChevronRight,
  faChevronDown,
  faFilter,
  faEllipsisV,
  faFileUpload,
  faSortDown,
  faTimesCircle,
  faDownload,
  faPencilAlt,
  faPlus,
  faCheck,
  faTimes,
  faCheckCircle,
  faUpload,
  faDollarSign,
  faList,
  faMedal,
  faFileDownload,
  faExclamationCircle,
  faCalendarAlt,
  faSearchPlus,
  faPen
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarFar } from "@fortawesome/free-regular-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faUserSecret,
  faPowerOff,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faSearch,
  faArrowRight,
  faUser,
  faChevronRight,
  faStarFar,
  faStar,
  faChevronDown,
  faFilter,
  faEllipsisV,
  faFileUpload,
  faSortDown,
  faTimesCircle,
  faDownload,
  faPencilAlt,
  faPlus,
  faCheck,
  faTimes,
  faCheckCircle,
  faUpload,
  faDollarSign,
  faList,
  faMedal,
  faFileDownload,
  faExclamationCircle,
  faFacebookF,
  faCalendarAlt,
  faSearchPlus,
  faPen
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

let cookiesDomain = window.location.hostname;
Vue.prototype.$cookiesDomain = cookiesDomain.replace("www", "");
// VueCookies.config('1y', '/', Vue.prototype.$cookiesDomain, '', 'Lax');

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);

import VueSlickCarousel from "vue-slick-carousel";
const Slick = {
  install(Vue, options) {
    Vue.component("slick", VueSlickCarousel);
  },
};

Vue.use(Slick);
// moment.locale(VueCookies.isKey("back_office_language") ? VueCookies.get("back_office_language") : 'th');
Vue.use(VueMoment, {
  moment,
});
Vue.component("datetime", Datetime);
Vue.component("editor", editor);

let globalData = new Vue({
  data: {
    $isLoading: false,
    $campaignName: "",
    $screenSize: {
      width: 0,
      height: 0,
    },
  },
});

Vue.mixin({
  computed: {
    $isLoading: {
      get: function() {
        return globalData.$data.$isLoading;
      },
      set: function(isLoading) {
        globalData.$data.$isLoading = isLoading;
      },
    },
    $campaignName: {
      get: function() {
        return globalData.$data.$campaignName;
      },
      set: function(campaignName) {
        globalData.$data.$campaignName = campaignName;
      },
    },
    $screenSize: {
      get: function() {
        return globalData.$data.$screenSize;
      },
      set: function(screenSize) {
        globalData.$data.$screenSize = screenSize;
      },
    },
  },
});
 
Vue.prototype.$language = VueCookies.isKey("back_office_language")
  ? VueCookies.get("back_office_language")
  : "th";
Vue.prototype.$callApi = callApi;

const MODE = process.env.VUE_APP_MODE || "DEV";
if (MODE === "DEV") {
  Vue.prototype.$sellerSiteUrl = "https://dev-siamensis.dosetech.co/";
  Vue.prototype.$baseUrl = "https://dev-admin-siamensis-api.dosetech.co"; //dev
  Vue.prototype.$googleMapKey = "AIzaSyDpFUl3VzjDzTMYPiksGMNnAkVf98I6maM"; //dev
  Vue.prototype.$imagePath = "https://dev-img-siamensis.dosetech.co";
  Vue.prototype.$tinyKey = "ih8i3lpm94w6qsyhwjh4861e60kfb8ptkee9vx6ftxwrsdnw";
  
}
if (MODE === "PROD") {
  Vue.prototype.$sellerSiteUrl = "";
  Vue.prototype.$baseUrl = ""; //prod
  Vue.prototype.$googleMapKey = ""; //prod
  Vue.prototype.$imagePath = "";
  Vue.prototype.$tinyKey = "ih8i3lpm94w6qsyhwjh4861e60kfb8ptkee9vx6ftxwrsdnw";
}
if (MODE === "UAT") {
  Vue.prototype.$sellerSiteUrl = "https://siamensis.co/";
  Vue.prototype.$baseUrl = "https://admin-siamensis-api.siamensis.co";
  Vue.prototype.$googleMapKey = "";
  Vue.prototype.$imagePath = "https://img-siamensis.siamensis.co";
  Vue.prototype.$tinyKey = "ih8i3lpm94w6qsyhwjh4861e60kfb8ptkee9vx6ftxwrsdnw";

  // Vue.prototype.$baseUrl = "https://api-uat-admin.getfin.me:230"; //uat
}
if (MODE === "DEMO") {
  Vue.prototype.$sellerSiteUrl = "https://dev-demo-admin.dosetech.co";
  Vue.prototype.$baseUrl = "https://dev-demo-admin-api.dosetech.co"; //demo
  Vue.prototype.$googleMapKey = "AIzaSyDpFUl3VzjDzTMYPiksGMNnAkVf98I6maM"; //demo
}

// google map
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: Vue.prototype.$googleMapKey,
    libraries: "places",
  },
});

Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY (HH:mm)";
Vue.prototype.$formatDateTimeFull = "DD MMM YYYY (HH:mm:ss)";
Vue.prototype.$formatTime = "HH:mm";
Vue.prototype.$headers = {
  "Content-Type": "application/json",
  culture: "th",
  Authorization: VueCookies.isKey("admin-token")
    ? `Bearer ${VueCookies.get("admin-token")}`
    : "null",
};
Vue.prototype.$headersZip = {
  "API-KEY": "SK1ADM1FND",
  "Content-Type": "application/zip",
  culture: Vue.prototype.$language,
  Authorization: VueCookies.isKey("admin-token")
    ? `Bearer ${VueCookies.get("admin-token")}`
    : "null",
};

LuxonSettings.defaultLocale = "th";

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!VueCookies.get("admin-token")) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresIsLogin)) {
    if (VueCookies.get("admin-token")) {
      if (to.name == "ResetPassword") {
        next();
      } else {
        next({
          path: "/",
        });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  el: "#app",
  library,
  i18n,
  router,
  icons,
  store,
  template: "<App/>",
  components: {
    App,
  },
});